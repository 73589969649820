import * as React from 'react'
import styled from 'styled-components/macro'
import Facebook from 'assets/images/SocialIcons/Facebook.png'
import Instagram from 'assets/images/SocialIcons/Instagram_White.png'
import LinkedIn from 'assets/images/SocialIcons/LinkedIn_White.png'
import TikTok from 'assets/images/SocialIcons/TikTok_White.png'
import Twitter from 'assets/images/SocialIcons/Twitter_White.png'
import YouTube from 'assets/images/SocialIcons/YouTube_White.png'
import Logo from 'assets/images/Logo1.svg'
import {Plus, Minus} from 'phosphor-react'
import {QUERIES} from 'common/constants'
import {Link, useLocation} from 'react-router-dom'
import RequestQuotePopup from 'marketing/ComingSoon/RequestQuotePopup'

const Wrapper = styled.div`
  background-color: var(--darker-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  position: relative;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 100%;
  padding: 16px;
  @media ${QUERIES.tabletAndUp} {
    padding: 96px 128px;
  }
  h3 {
    font-size: calc(20 / 16 * 1rem);
    font-weight: bold;
    font-family: 'Rubik';
    text-transform: uppercase;
    padding-bottom: 16px;
  }
`

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  @media ${QUERIES.tabletAndUp} {
    flex-direction: row;
  }
  width: 100%;
  justify-content: space-between;
`

const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  @media ${QUERIES.tabletAndUp} {
    flex-direction: row;
  }
  width: 100%;
  gap: 16px;
  @media ${QUERIES.tabletAndUp} {
    gap: 72px;
  }
`

const SocialSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  padding-top: 48px;
  @media ${QUERIES.tabletAndUp} {
    padding: 0;
    align-items: flex-end;
  }
`

const EmailLink = styled.a`
  text-decoration: none;
  color: white !important;
`

const SocialIcons = styled.div`
  display: flex;
  gap: 8px;
`

const LogoAndCopyright = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  gap: 16px;
  img {
    height: 48px;
  }
  @media ${QUERIES.tabletAndUp} {
    padding-top: 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    height: auto;
  }
`

const Copyright = styled.div`
  font-size: calc(14 / 16 * 1rem);
  @media ${QUERIES.tabletAndUp} {
    font-size: calc(18 / 16 * 1rem);
    font-style: italic;
  }
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(Link)`
  font-weight: 300;
  font-family: 'Mulish';
  color: white;
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  :hover {
    font-weight: 700;
    cursor: pointer;
  }
  ::after {
    content: attr(data-text);
    content: attr(data-text) / '';
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: 700;

    @media speech {
      display: none;
    }
  }
`

const Header = styled.h3`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  white-space: nowrap;
  @media ${QUERIES.tabletAndUp} {
    cursor: initial;
    padding: 0;
  }
`

const ExpandIcon = styled(Plus)`
  display: flex;
  align-items: center;
  @media ${QUERIES.tabletAndUp} {
    display: none;
  }
`

const CollapseIcon = styled(Minus)`
  display: flex;
  align-items: center;
  @media ${QUERIES.tabletAndUp} {
    display: none;
  }
`

const CollapsingSection = styled.div`
  display: flex;
  max-height: ${p => (p.collapsed === 'false' ? '200px' : 0)};
  padding: 0 32px;
  gap: 4px;
  @media ${QUERIES.tabletAndUp} {
    max-height: 1000px;
    padding: 0;
    gap: 0;
  }
  flex-direction: column;
  transition: all 0.5s ease;
  overflow: hidden;
  a {
    color: white !important;
    text-decoration: none !important;
  }
`

const Footer = () => {
  const [showingGolfInvite, setShowingGolfInvite] = React.useState(true)
  const [showingExplore, setShowingExplore] = React.useState(true)
  const [showingQuestions, setShowingQuestions] = React.useState(true)
  const [showQuoteModal, setShowQuoteModal] = React.useState(false)

  const location = useLocation()

  React.useEffect(() => {
    setShowingGolfInvite(true)
    setShowingExplore(true)
    setShowingQuestions(true)
  }, [location])

  return (
    <Wrapper>
      <Container>
        <TopSection>
          <LinksSection>
            <LinksContainer>
              <Header onClick={() => setShowingGolfInvite(!showingGolfInvite)}>
                Golf Invite
                {showingGolfInvite ? (
                  <ExpandIcon weight={'bold'} size={24} />
                ) : (
                  <CollapseIcon weight={'bold'} size={24} />
                )}
              </Header>
              <CollapsingSection collapsed={String(showingGolfInvite)}>
                <StyledLink to="/about">Our Story</StyledLink>
                <StyledLink to="/contact">Contact Us</StyledLink>
                <StyledLink to="/employment">Employment</StyledLink>
                <StyledLink
                  as="div"
                  onClick={() => setShowQuoteModal(true)}
                  data-text="Request a Quote"
                >
                  Request a Quote
                </StyledLink>
              </CollapsingSection>
            </LinksContainer>
            <LinksContainer>
              <Header onClick={() => setShowingExplore(!showingExplore)}>
                Explore
                {showingExplore ? (
                  <ExpandIcon weight={'bold'} size={24} />
                ) : (
                  <CollapseIcon weight={'bold'} size={24} />
                )}
              </Header>
              <CollapsingSection collapsed={String(showingExplore)}>
                <StyledLink to="/home">Home</StyledLink>
                {/* <StyledLink
                  as={'a'}
                  href="https://shop.golfinvite.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Shop
                </StyledLink> */}
                <StyledLink to="/shop">Shop</StyledLink>
                <StyledLink to="/oncourse">On-Course</StyledLink>
                <StyledLink
                  to="/destinations"
                  data-text="Premier Destination Partners"
                >
                  Premier Destination Partners
                </StyledLink>
                <StyledLink to="/planning">Planning</StyledLink>
                <StyledLink
                  as={'a'}
                  href="https://www.golfinvite.net"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sweet Spot Software Login
                </StyledLink>
                <StyledLink to="/gifts">Gifts</StyledLink>
              </CollapsingSection>
            </LinksContainer>
            <LinksContainer>
              <Header onClick={() => setShowingQuestions(!showingQuestions)}>
                Questions?
                {showingQuestions ? (
                  <ExpandIcon weight={'bold'} size={24} />
                ) : (
                  <CollapseIcon weight={'bold'} size={24} />
                )}
              </Header>
              <CollapsingSection collapsed={String(showingQuestions)}>
                <div>P: 630-657-5700</div>
                <div>
                  <EmailLink href="mailto:sales@golfinvite.com">
                    E: sales@golfinvite.com
                  </EmailLink>
                </div>
                <div>www.golfinvite.com</div>
              </CollapsingSection>
            </LinksContainer>
          </LinksSection>
          <SocialSection>
            <h3>Follow Us</h3>
            <SocialIcons>
              <a
                href="https://www.linkedin.com/company/golf-invite-inc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedIn} alt="LinkedIn" />
              </a>
              <a
                href="https://instagram.com/golfinviteinc?igshid=1ue4k70vwlxv7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Instagram} alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/golfeventstoatee/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Facebook} alt="Facebook" />
              </a>
              <a
                href="https://www.tiktok.com/@golfinviteinc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TikTok} alt="TikTok" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCCEy9hk8GLhBG7nMXCwWscQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={YouTube} alt="YouTube" />
              </a>
              <a
                href="https://twitter.com/golfinviteinc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Twitter} alt="Twitter" />
              </a>
            </SocialIcons>
          </SocialSection>
        </TopSection>
        <LogoAndCopyright>
          <Copyright>{new Date().getFullYear()} Golf Invite, Inc.</Copyright>
          <a href="/">
            <img src={Logo} alt="Golf Invite Logo" />
          </a>
        </LogoAndCopyright>
      </Container>
      <RequestQuotePopup
        setShowQuoteModal={setShowQuoteModal}
        showQuoteModal={showQuoteModal}
      />
    </Wrapper>
  )
}
export default Footer
